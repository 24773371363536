import {GetRequest} from "../../helpers/Request";
import {TrainingResultModel} from "./model/TrainingResultModel";
import {makeAutoObservable} from "mobx";
import {LoadingModel} from "../shared/LoadingModel";
import {getDateRes, getIndexResult, getTimeRes, msToTime, msToTimeSecond} from "../../helpers/numberHelp/numberHelp";
import React from "react";
import IResultService from "./service/IResultService";
import NumberResultService from "./service/NumberResultService";
import BinsResultService from "./service/BinsResultService";
import WordResultService from "./service/WordResultService";
import NameResultService from "./service/NameResultService";
import ImageResultService from "./service/ImageResultService";
import CardsResultService from "./service/CardsResultService";
import Highcharts from "highcharts";
import {ReactModalStore} from "../modal/ReactModalStore";
import ConstantsColor from "../../helpers/ConstantsColor";


export default class TrainingResultStore {
    trainingId?: string;
    showDiagram: boolean = false;
    model: LoadingModel<TrainingResultModel>;
    optionsPie = {
        title: "",
        pieHole: 0.8,
        colors: [ConstantsColor.Green, ConstantsColor.Red],
        chartArea: {left: 0, top: 0, width: '100%', height: '100%'},
        legend: 'none',
        pieSliceText: "none",
    };
    sharedStore = new ReactModalStore();

    constructor(trainingId?: string, model?: LoadingModel<TrainingResultModel>) {
        makeAutoObservable(this, {}, {autoBind: true})
        this.trainingId = trainingId;
        this.model = model ?? LoadingModel.createLoaded<TrainingResultModel>();
    }

    getService(): IResultService | undefined {
        if (!this.model.IsLoadedModel())
            return undefined;
        if (this.model.Model!.mode === 'number')
            return new NumberResultService();
        if (this.model.Model!.mode === 'bins')
            return new BinsResultService();
        if (this.model.Model!.mode === 'word')
            return new WordResultService();
        if (this.model.Model!.mode === 'name')
            return new NameResultService();
        if (this.model.Model!.mode === 'image')
            return new ImageResultService();
        if (this.model.Model!.mode === 'cards')
            return new CardsResultService();
        return undefined;
    }

    setModel(model: LoadingModel<TrainingResultModel>) {
        this.model = model;
    }

    get dataPie() {
        return [
            ['Состояние', 'Кол-во'],
            ['Правильные', (this.model.Model?.items.length ?? 0) - (this.model.Model?.error ?? 0)],
            ['Ошибки', (this.model.Model?.error ?? 0)]
        ];
    }

    sharedOpen(){
        this.sharedStore.open();
    }

    get opinionHighcharts(): Highcharts.Options {
        return {
            chart: {
                type: 'arearange',
                zooming: {
                    type: 'x'
                },
            },
            rangeSelector: {
                verticalAlign: 'top',
                allButtonsEnabled: true,
                selected: 2,
                floating: true,
                // y: 250,
                inputPosition: {
                    align: 'left',
                    x: 0,
                    y: 0
                },
                buttonPosition: {
                    align: 'right',
                    x: 0,
                    y: 0
                },
            },
            tooltip: {
                formatter: function () {
                    let s = `<br />Номер: ${this.x}`;
                    s += `<br />Время: ${this.y}c.`;
                    s += `<br />Значение: ${this.point.custom.dictionary}`;
                    s += `<br />Ответ: ${this.point.custom.answer}`;
                    return s;
                },
                shared: true,
            },
            title: {
                text: 'Темп запоминания'
            },
            xAxis: {
                allowDecimals: false,
                min: 1,
                minPadding: 0,
                maxPadding: 0,
                plotLines: [{
                    color: '#888',
                    value: 0,
                    width: 1,
                    label: {
                        rotation: 90
                    }
                }],
            },
            yAxis: [{
                lineWidth: 1,
                gridLineWidth: 1,
                title: {
                    text: ''
                },
                tickWidth: 1,
                tickLength: 5,
                labels: {
                    align: 'left',
                    x: 8
                }
            }],
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                area: {
                    fillOpacity: 1,
                    lineWidth: 1,
                    step: 'center'
                }
            },
            series: [{
                name: '',
                data: this.model.Model?.items.map((x, index) => {
                    return {
                        y: x.timeOnElement / 1000,
                        x: index,
                        color: x.evenly ? ConstantsColor.Green : ConstantsColor.Red,
                        custom: {
                            time: x.timeOnElement,
                            dictionary: x.dictionary,
                            evenly: x.evenly,
                            answer: x.answer,
                        }
                    }
                }) ?? [],
                type: 'column',
                borderColor: "#ffffff",
                showInLegend: false,
                tooltip:
                    {
                        valueDecimals: 1
                    }
            }]
        };
    }

    openCloseDiagram() {
        this.showDiagram = !this.showDiagram;
    }

    request() {
        if (this.trainingId)
            GetRequest<TrainingResultModel>("/Training/Search?id=" + this.trainingId)
                .then(this.setModel)
    }

    get coefficient(): number {
        if (this.model.IsLoadedModel()) {
            if (this.model.Model!.mode === 'bins') {
                if (this.model.Model!.complexity === "1")
                    return 3;
                if (this.model.Model!.complexity === "2")
                    return 6;
                if (this.model.Model!.complexity === "3")
                    return 9;
            }
        }
        return 1;
    }

    get error(): string {
        if (this.model.IsLoadedModel()) {
            return (this.model.Model!.error * this.coefficient).toString();
        }
        return '0';
    }

    get rightDiagram(): string {
        if (this.model.IsLoadedModel()) {
            return ((this.model.Model!.items.length - this.model.Model!.error) * this.coefficient).toString();
        }
        return '0';
    }

    get right(): string {
        if (this.model.IsLoadedModel()) {
            return this.model.Model!.countRightImages.toString();
        }
        return '0';
    }

    get textRight(): string {
        if (this.model.IsLoadedModel() && this.model.Model) {
            let model = this.model.Model;
            console.log('ww', model.complexity)
            switch (model.mode){
                case "cards":
                    if (model.complexity === "3")
                        return "";
                    return "карты"
                case "word":
                    return "слов"
                case "image":
                    console.log('ww', model.complexity)
                    if (model.complexity === "1")
                        return "фракталов";
                    if (model.complexity === "2")
                        return "картинок";
                    return ""
                case "bins":
                    return "знаков"
                case "name":
                    return "имён"
                case "number":
                    return "цифр"
            }
        }
        return '';
    }

    get index(): string {
        if (this.model.IsLoadedModel()) {
            return getIndexResult(this.model.Model!.index);
        }
        return '0.00';
    }

    get speedMiddle(): string {
        if (this.model.IsLoadedModel()) {
            return msToTimeSecond(this.model.Model!.speed * 1000);
        }
        return '00:000';
    }

    get speedMax(): string {
        if (this.model.IsLoadedModel()) {
            return msToTimeSecond(Math.min.apply(Math, this.model.Model!.items
                .map(function (val) {
                    return val.timeOnElement;
                })));
        }
        return '0';
    }

    get speedMin(): string {
        if (this.model.IsLoadedModel()) {
            return msToTimeSecond(Math.max.apply(Math, this.model.Model!.items
                .map(function (val) {
                    return val.timeOnElement;
                })));
        }
        return '0';
    }

    get timeGet(): string {
        if (this.model.IsLoadedModel()) {
            return getTimeRes(this.model.Model!.date);
        }
        return '0';
    }

    get dateGet(): string {
        if (this.model.IsLoadedModel()) {
            return getDateRes(this.model.Model!.date);
        }
        return '0';
    }

    get timeAll(): string {
        if (this.model.IsLoadedModel()) {
            return msToTime(this.model.Model!.timeAll);
        }
        return '0';
    }

    getItems(): JSX.Element {
        if (this.model.IsLoadedModel()) {
            return (<div id="ans-box-res">
                <div className="ans-box-container-res">
                    {this.getService()?.GetItems(this.model.Model!)}
                </div>
            </div>)
        }
        return <></>
    }
}