import {CustomComponent} from "../CustomComponent";
import {ChangeEvent} from "react";
import {observer} from "mobx-react";
import {CheckTextStatus, CheckTextStore} from "./CheckTextStore";
import './checkTextComponent.scss'
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";


interface Props {
    correctAnswer: string
    changeText: (val: string) => void;
    answer: string;
    tryAnswer: (isCorrect: boolean) => void;
}

@observer
export class CheckTextComponent extends CustomComponent<Props, any> {
    private store: CheckTextStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new CheckTextStore(this.props.correctAnswer,
            this.props.answer,
            this.props.changeText, this.props.tryAnswer);
        this.changeAnswer = this.changeAnswer.bind(this);
    }

    componentDidMount() {
        KeyboardManager.add('Enter', this.store.checkText);
    }

    componentWillUnmount() {
        KeyboardManager.remove('Enter', this.store.checkText);
    }

    get classStatus(): string {
        if (this.store.status === CheckTextStatus.noValid)
            return "incorrect_answer";
        if (this.store.status === CheckTextStatus.valid)
            return "correct_answer";
        return "";
    }

    changeAnswer(e: ChangeEvent<HTMLInputElement>) {
        this.store.changeAnswer(e.currentTarget.value)
    }

    render() {
        return <div className="check-answer-component">
            <div className={"check-answer_input_block " + this.classStatus}>
                <input className="check-answer_input" type="text" value={this.store.answer}
                       onChange={this.changeAnswer}/>
                <img src="https://powermemory.io/images/users/f3590673-90df-4c3b-bfe8-40dbc37dea58.jpg"
                     alt="Проверить ответ" className="check-answer_img" onClick={this.store.checkText}/>
            </div>
        </div>;
    }
}