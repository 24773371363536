import {CustomComponent} from "../../../helpers/CustomComponent";
import './matryoshkaComponent.scss'
import React from "react";
import {BlockTextComponent} from "../../../helpers/blockText/BlockTextComponent";
import {MatryoshkaState, MatryoshkaStore} from "./MatryoshkaStore";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent";
import {CustomButton} from "../../../helpers/CustomButton";
import {BottomBlockComponent} from "../../../helpers/bottomBlock/BottomBlockComponent";
import {MatryoshkaItemComponent} from "./MatryoshkaItemComponent";
import {observer} from "mobx-react";
import {MatryoshkaCheckItemComponent} from "./MatryoshkaCheckItemComponent";

interface Props {

}

@observer
export class MatryoshkaComponent extends CustomComponent<Props, any> {
    private store: MatryoshkaStore = new MatryoshkaStore();

    constructor(props: Readonly<Props>) {
        super(props);
    }

    next() {

    }

    prev() {

    }

    render() {
        return <div className={"matryoshka_component"}>
            {
                this.store.state === MatryoshkaState.remember &&
                <>
                    <BlockTextComponent hasBackground={true} className={"block-main-component"}>
                        <TitleComponent typeTitle={TypeTitle.homeTaskDescription}
                                        text={this.store.model.items.map(x => x.word).join(", ")}/>
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CustomButton text={"Закончить"} onclick={this.store.nextStep} className={""}/>
                    </BottomBlockComponent>
                </>
            }
            {
                this.store.state === MatryoshkaState.writeAnswer &&
                <>
                    <TitleComponent typeTitle={TypeTitle.homeWorkTitle}
                                    typePosition={TypePositionText.center}
                                    text={"Вспомните слова по образам на мысленном экране соблюдая " +
                                        "главный энергосейв этап припоминания БП + У"}/>
                    <BlockTextComponent className={"block-write_answer-component"} withoutBlockContent={true}>
                        {
                            this.store.model.items.map((x,index) =>
                                <MatryoshkaItemComponent model={x}
                                                         position={index}
                                                         onChange={this.store.onChange}
                                                         clickHint={this.store.clickHint}
                                />)
                        }
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CustomButton text={"Проверить"} onclick={this.store.nextStep} className={""}/>
                    </BottomBlockComponent>
                </>
            }
            {
                this.store.state === MatryoshkaState.checkAnswer &&
                <>
                    <TitleComponent typeTitle={TypeTitle.homeWorkTitle}
                                    typePosition={TypePositionText.center}
                                    text={"Усильте слабые и не созданные связи"}/>
                    <BlockTextComponent className={"block-write_answer-component"} withoutBlockContent={true}>
                        {
                            this.store.model.items.map((x,index) =>
                                <MatryoshkaCheckItemComponent model={x}
                                                              position={index}
                                />)
                        }
                    </BlockTextComponent>
                    <BottomBlockComponent className={"component_middle-block"}>
                        <CustomButton text={"Далее"} onclick={this.store.nextStep} className={""}/>
                    </BottomBlockComponent>
                </>
            }
        </div>;
    }
}