import React, {Component} from 'react'
import './../training/trainingBaseComponent.scss'
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {SelectorPros} from "../shared/selector/CustomSelector";
import SelectorWithText from "../shared/selector/SelectorWithText";
import {MainLearnLanguageStepsComponent} from "./components/MainLearnLanguageStepsComponent";
import {CustomButtonView} from "../training/components/CustomButtonView";
import TrainingLearnLanguageBaseStore, {TrainingLearnLanguageMenuType} from "./store/TrainingLearnLanguageBaseStore";
import {TrainingMemoryRussianCreateViewModel, TrainingMemoryRussianStage} from "./model/LearnLanguageMemberModel";
import {ContainerStageMain} from "./ContainerStageMain";
import {rangeEngIng} from "../trainingCoding/store/TrainingCodingConstants";

interface Props {
    startTraining: (model: TrainingMemoryRussianCreateViewModel) => void
}

@observer
export class TrainingLearnStepLanguageComponent extends Component<Props, {}> {
    store: TrainingLearnLanguageBaseStore;

    constructor(props: Readonly<Props>) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.changeSelectorGroups = this.changeSelectorGroups.bind(this);
        this.changeSelectorCategory = this.changeSelectorCategory.bind(this);
        this.changeSelectorSystemCoding = this.changeSelectorSystemCoding.bind(this);
        this.changeSelectorIncode = this.changeSelectorIncode.bind(this);
        this.store = new TrainingLearnLanguageBaseStore(this.props.startTraining);
    }

    componentDidMount() {
        this.store.init();
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    onClick(id: bigint) {
        this.store.setStage(id);
    }

    changeSelectorGroups(sel: SelectorPros | undefined) {
        this.store.requestModel.languageVocabularyType = parseInt(sel!.value ?? 1);
    }

    changeSelectorCategory(sel: SelectorPros | undefined) {
        // this.store.setCategory(sel?.value ?? "");
        // this.props.changeModel.changeElementAtGo(sel?.value ?? "2");
    }

    changeSelectorSystemCoding(sel: SelectorPros | undefined) {
        this.store.requestModel.systemCoding = sel?.value;
    }

    changeSelectorIncode(sel: SelectorPros | undefined) {
        this.store.requestModel.rateCoding = sel?.value;
    }

    render() {
        // if (!this.store.responseModel.IsLoadedModel())
        //     return <Loading/>
        return (
            <div className="training-sport-base">
                <div className="mode-training">
                    <Link to={"/Training"} id="next-simulators-label"/>
                </div>

                <div className="simulators" style={{flexDirection: 'column'}}>
                    {/*<RouteLearnLanguageCoding store={this.store}/>*/}
                    <div className="container-vocabulary">
                        <div className="container-vocabulary-title">
                            Выберите словарь для запоминания английских слов
                        </div>
                        <div className="container-vocabulary-block">
                            {
                                this.store.requestModel.stage !== TrainingMemoryRussianStage.IncodeCoding &&
                                <>
                                    <SelectorWithText value={this.store.requestModel.languageVocabularyType.toString()}
                                                      text="Словари"
                                                      values={this.store.dictionaries}
                                                      width="225px"
                                                      onChange={this.changeSelectorGroups}/>
                                    <SelectorWithText value={this.store.requestModel.category}
                                                      text="Пачка слов"
                                                      values={this.store.packageWords}
                                                      width="225px"
                                                      onChange={this.changeSelectorCategory}/>
                                </>
                            }
                            {
                                this.store.requestModel.stage === TrainingMemoryRussianStage.IncodeCoding &&
                                <>
                                    <SelectorWithText value={this.store.requestModel.systemCoding}
                                                      text="Выберите редакцию"
                                                      values={this.store.editorial}
                                                      width="225px"
                                                      onChange={this.changeSelectorSystemCoding}/>
                                    <SelectorWithText value={this.store.requestModel.rateCoding}
                                                      text="Выберите вид инкодов"
                                                      values={rangeEngIng().filter(x => x.value !== 'all')}
                                                      width="225px"
                                                      onChange={this.changeSelectorIncode}/>
                                </>
                            }
                        </div>
                    </div>
                    {
                        this.store.state === TrainingLearnLanguageMenuType.stage &&
                        <div className="container-steps">
                            {
                                this.store.packages
                                    .map(x =>
                                        <ContainerStageMain key={x.id} model={x} onClick={this.onClick}/>)
                            }
                        </div>
                    }
                    {
                        this.store.state === TrainingLearnLanguageMenuType.step &&
                        <div className="modeTypeTraining">
                            <MainLearnLanguageStepsComponent store={this.store}/>
                            <CustomButtonView click={this.store.startTraining}/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}



