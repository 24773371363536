import {Route, Routes} from 'react-router-dom'
import {AboutPage} from './pages/AboutPage'
import {Navigation} from './components/navigator/Navigation'
import React, {Suspense} from "react";
import {Loading} from "./components/shared/Loading";
import {HomePage} from "./pages/home/HomePage";
import 'app.css'
import {SettingsPage} from "./pages/settings/SettingsPage";
import {NewsPage} from "./pages/news/NewsPage";
import {StatisticsPage} from "./pages/statistics/StatisticsPage";
import {RoutePage} from "./pages/route/RoutePage";
import {SettingsCodingPage} from "./pages/settingsCoding/SettingsCodingPage";
import {Mnemocard} from "./components/mnemocard/Mnemocard";
import {TrainingPage} from "./pages/trainingMode/TrainingPage";
import SettingsCodingItemsRoutePage from "./pages/settingsCoding/SettingsCodingItemsPage";
import {TrainingResultSearchPage} from "./pages/trainingResult/TrainingResultPage";
import {TopUsersPage} from "./pages/topUsers/TopUsersPage";
import {TrainingSportPage} from "./pages/trainingSport/TrainingSportPage";
import NewsPostPage from "./pages/news/NewsPostPage";
import {TrainingCodingPage} from "./pages/trainingCoding/TrainingCodingPage";
import {NotFoundPage} from "./pages/notFound/NotFoundPage";
import {NavigationFooter} from "./components/navigator/NavigationFooter";
import {BallsResults} from "./components/ball/MobileBallsResultsModel";
import {PrivacyPolicyPage} from "./pages/rules/PrivacyPolicyPage";
import {ManifestPage} from "./pages/rules/ManifestPage";
import {RulesPage} from "./pages/rules/RulesPage";
import {MotivationPage} from "./pages/rules/MotivationPage";
import {StorePage} from "./pages/store/StorePage";
import {LearnLanguagePage} from "./pages/EnglishLanguage/LearnLanguagePage";
import {AdministrationPage} from "./pages/Administration/AdministrationPage";
import {HomeWorkPage} from "./pages/homeWork/HomeWorkPage";
import {UniversityPage} from "./pages/university/UniversityPage";
import {TrainingBasePage} from "./pages/trainingBase/TrainingSportPage";
import {PurchasesPage} from "./pages/purchases/PurchasesPage";

function App() {
    return (
        <div id="container">
            <Navigation/>
            {/*<Routes>*/}
            {/*</Routes>*/}
            <BallsResults isHomePage={false}/>
            <Mnemocard/>
            <Routes>
                {/*<Route path="/@:test" element={<Suspense fallback={<Loading/>}>*/}
                {/*    <Mnemocard/>*/}
                {/*</Suspense>}/>*/}
                <Route path="/" element={<Suspense fallback={<Loading/>}>
                    <HomePage/>
                </Suspense>}/>
                <Route path="/homeWork/*" element={<Suspense fallback={<Loading/>}>
                    <HomeWorkPage/>
                </Suspense>}/>
                <Route path="/Settings" element={<Suspense fallback={<Loading/>}>
                    <SettingsPage/>
                </Suspense>}/>
                <Route path="/News" element={<Suspense fallback={<Loading/>}>
                    <NewsPage/>
                </Suspense>}/>
                <Route path="/News/:link" element={<Suspense fallback={<Loading/>}>
                    <NewsPostPage/>
                </Suspense>}/>
                <Route path="/Statistics/*" element={<Suspense fallback={<Loading/>}>
                    <StatisticsPage/>
                </Suspense>}/>
                <Route path="/About" element={<Suspense fallback={<Loading/>}>
                    <AboutPage/>
                </Suspense>}/>
                <Route path="/Route" element={<Suspense fallback={<Loading/>}>
                    <RoutePage/>
                </Suspense>}/>
                <Route path="/SettingsCoding" element={<Suspense fallback={<Loading/>}>
                    <SettingsCodingPage/>
                </Suspense>}/>
                <Route path="/TopUsers" element={<Suspense fallback={<Loading/>}>
                    <TopUsersPage/>
                </Suspense>}/>
                <Route path="/SettingsCoding/:mode" element={<Suspense fallback={<Loading/>}>
                    <SettingsCodingItemsRoutePage/>
                </Suspense>}/>
                <Route path="/SettingsCoding/:mode/:type" element={<Suspense fallback={<Loading/>}>
                    <SettingsCodingItemsRoutePage/>
                </Suspense>}/>
                <Route path="/Training" element={<Suspense fallback={<Loading/>}>
                    <TrainingPage/>
                </Suspense>}/>
                <Route path="/Training/summingUp" element={<Suspense fallback={<Loading/>}>
                    <TrainingCodingPage/>
                </Suspense>}/>
                <Route path="/Training/Sport" element={<Suspense fallback={<Loading/>}>
                    <TrainingSportPage/>
                </Suspense>}/>
                <Route path="/Training/Base" element={<Suspense fallback={<Loading/>}>
                    <TrainingBasePage/>
                </Suspense>}/>
                <Route path="/Training/LearnLanguage" element={<Suspense fallback={<Loading/>}>
                    <LearnLanguagePage/>
                </Suspense>}/>
                <Route path="/Training/Search" element={<Suspense fallback={<Loading/>}>
                    <TrainingResultSearchPage/>
                </Suspense>}/>
                <Route path="/PrivacyPolicy" element={<Suspense fallback={<Loading/>}>
                    <PrivacyPolicyPage/>
                </Suspense>}/>
                <Route path="/Manifest" element={<Suspense fallback={<Loading/>}>
                    <ManifestPage/>
                </Suspense>}/>
                <Route path="/Rules" element={<Suspense fallback={<Loading/>}>
                    <RulesPage/>
                </Suspense>}/>
                <Route path="/Motivation" element={<Suspense fallback={<Loading/>}>
                    <MotivationPage/>
                </Suspense>}/>
                <Route path="/Store" element={<Suspense fallback={<Loading/>}>
                    <StorePage/>
                </Suspense>}/>
                <Route path="/University/*" element={<Suspense fallback={<Loading/>}>
                    <UniversityPage/>
                </Suspense>}/>
                <Route path="/Administration/*" element={<Suspense fallback={<Loading/>}>
                    <AdministrationPage/>
                </Suspense>}/>
                <Route path="/Purchases/*" element={<Suspense fallback={<Loading/>}>
                    <PurchasesPage/>
                </Suspense>}/>
                <Route path="*" element={<Suspense fallback={<Loading/>}>
                    <NotFoundPage/>
                </Suspense>}/>
            </Routes>
            <NavigationFooter/>
        </div>
    )
}

export default App;
