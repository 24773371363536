import React from 'react'
import './trainingResultPage.scss'
import {observer} from "mobx-react";
import TrainingResultStore from "../../components/trainingResult/TrainingResultStore";
import {CustomComponent} from "../../helpers/CustomComponent";
import {Loading} from "../../components/shared/Loading";
import {LoadingModel} from "../../components/shared/LoadingModel";
import {TrainingResultModel} from "../../components/trainingResult/model/TrainingResultModel";
import {useSearchParams} from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Chart} from "react-google-charts";
import TitleStore from "../../components/authorization/store/TitleStore";
import {UserInfoComponent} from "../../components/trainingResult/view/UserInfoComponent";
import {CustomButton} from "../../helpers/CustomButton";
import {CheckScreen, TypeScreen} from "../../context/TypeScreen";
import {ResAndShareBox} from "../../components/trainingResult/view/ResAndShareBox";
import {SharedComponent} from "../../components/trainingResult/view/SharedComponent";

interface RouteParams {
    id?: string;
    model?: LoadingModel<TrainingResultModel>;
}

@observer
export class TrainingResultPage extends CustomComponent<RouteParams, {}> {
    store: TrainingResultStore;

    constructor(props: Readonly<RouteParams>) {
        super(props);
        this.store = new TrainingResultStore(this.props.id, this.props.model);
    }

    componentDidMount() {
        TitleStore.setTitle("Результат тренировки");
        this.store.request();
    }

    render() {
        if (!this.store.model.hasModel) {
            return <Loading/>
        }
        return (
            <div className="training-result">
                <div id="res-contener">
                    <UserInfoComponent
                        fullname={this.store.model.Model!.username}
                        imgSrc={this.store.model.Model!.imgSrc}
                        rang={this.store.model.Model!.rang}
                        isPremium={this.store.model.Model!.isPremium}
                        link={this.store.model.Model!.link}
                        bestTraining={this.store.model.Model!.bestTraining}
                        dis={this.store.model.Model!.dIS}
                        timeGet={this.store.timeGet}
                        dateGet={this.store.dateGet}
                        openCloseDiagram={this.store.openCloseDiagram}
                        id={this.store.model.Model!.id}
                        sharedOpen={()=>{}}
                    />

                    {/*@*Круговая диаграмма*@*/}
                    <div className="res-diagram-box">
                        <Chart
                            chartType="PieChart"
                            data={this.store.dataPie}
                            options={this.store.optionsPie}
                            width={'210px'}
                            height={'210px'}
                        />

                        <div id="res-diagram">
                            {
                                this.store.error !== '0' &&
                                <span className="spincrement" id="err-diagram">{this.store.error}</span>
                            }
                            <span className="spincrement" id="right-diagram">{this.store.rightDiagram}</span>
                            <span className="spincrement" id="right-number">{this.store.right} {this.store.textRight}</span>
                        </div>
                    </div>

                    {/*@*Время запоминания и индекс*@*/}
                    <div className="res-parametrs-box">
                        <div id="res-index" data-title="Индекс запоминания">
                            <div id="index">{this.store.index}</div>
                        </div>
                        <div id="res-time" data-title="Время запоминания [MM:СС:mmm]">{this.store.timeAll}</div>
                        <div className="res-speed-box">
                            <div id="res-speed" data-title="Темп запоминания [CС:ммм]">{this.store.speedMiddle}</div>
                            <div className="max-min-speed-box">
                                <div id="max-res-speed">{this.store.speedMax}</div>
                                <div id="min-res-speed">{this.store.speedMin}</div>
                            </div>
                        </div>
                    </div>
                    {
                        CheckScreen([TypeScreen.Mobile]) &&
                        <ResAndShareBox timeGet={this.store.timeGet}
                                        dateGet={this.store.dateGet}
                                        toShare={this.store.sharedOpen}
                                        openCloseDiagram={this.store.openCloseDiagram}/>
                    }
                </div>
                {
                    this.store.showDiagram &&
                    <div id="element-diagram">
                        <HighchartsReact
                            containerProps={{
                                style: {
                                    width: '100%',
                                    height: '500px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }
                            }}
                            highcharts={Highcharts}
                            options={this.store.opinionHighcharts}/>
                    </div>
                }
                <div className="result-block">
                    {
                        this.store.getItems()
                    }
                    <CustomButton text={"Тренироваться"} className="repeat-tren"/>
                </div>
                <SharedComponent id={this.store.model.Model!.id} store={this.store.sharedStore}/>
            </div>
        )
    }
}

export function TrainingResultSearchPage() {
    const [search] = useSearchParams();
    return (
        <TrainingResultPage id={search.get('id') ?? undefined}/>
    );
}