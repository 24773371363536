import React, {Component} from 'react'
import {observer} from "mobx-react";
import TrainingLearnLanguageStore from "../../components/trainingLearnLanguage/store/TrainingLearnLanguageStore";
import {
    TrainingLearnLanguageMemberComponent
} from "../../components/trainingLearnLanguage/TrainingLearnLanguageMemberComponent";
import {
    TrainingLearnLanguageResultComponent
} from "../../components/trainingLearnLanguage/TrainingLearnLanguageResultComponent";
import {
    TrainingLearnStepLanguageComponent
} from "../../components/trainingLearnLanguage/TrainingLearnStepLanguageComponent";
import TitleStore from "../../components/authorization/store/TitleStore";
import {
    TrainingLearnLanguageDescriptionStageComponent
} from "../../components/trainingLearnLanguage/TrainingLearnLanguageDescriptionStageComponent";


export enum StateTrainingLearnLanguage {
    'steps' = -1,
    'base' = 0,
    'loadingImage' = 1,
    'member' = 2,
    'description' = 5,
    'write' = 3,
    'result' = 4,
}

@observer
export class LearnLanguagePage extends Component<{}, {}> {
    store: TrainingLearnLanguageStore;

    constructor(props: Readonly<{}>) {
        super(props);
        this.store = new TrainingLearnLanguageStore();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        TitleStore.setTitle("Английский язык");
    }

    render() {
        return (
            <>
                {this.store.state === StateTrainingLearnLanguage.base &&
                    <TrainingLearnStepLanguageComponent startTraining={this.store.startTrainingRequest}/>
                }
                {this.store.state === StateTrainingLearnLanguage.description &&
                    <TrainingLearnLanguageDescriptionStageComponent model={this.store.modelMember.Model!}
                                                                    finishTrainingRequest={this.store.finishDescription}/>
                }
                {this.store.state === StateTrainingLearnLanguage.member &&
                    <TrainingLearnLanguageMemberComponent model={this.store.modelMember.Model!}
                                                          transportModel={{
                                                              continueTraining: this.store.continueTrainingRequest,
                                                              resultTraining: this.store.resultTrainingRequest,
                                                              finishTraining: this.store.finishTrainingRequest
                                                          }}
                    />
                }
                {this.store.state === StateTrainingLearnLanguage.result &&
                    <TrainingLearnLanguageResultComponent model={this.store.modelResult.Model!}/>
                }
            </>
        )
    }
}
