import React, {Component} from 'react'
import {observer} from "mobx-react";
import './customButtonView.scss';

interface Props {
    click: () => void;
    text?: string;
    style?: React.CSSProperties;
    readonly?: boolean
}

@observer
export class CustomButtonView extends Component<Props, {}> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.startTraining = this.startTraining.bind(this);
    }

    startTraining() {
        this.props.click();
    }

    get onlyRead(): boolean {
        return this.props.readonly === true;
    }

    get cssProperty(): React.CSSProperties {
        return {
            ...{
                border: this.onlyRead ? "2px solid #afafaf" : "2px solid #000",
                color: this.onlyRead ? "#afafaf" : "#000",
                pointerEvents: this.onlyRead ? "none" : "all",
            }, ...this.props.style
        };
    }

    render() {
        return (
            <div className="custom-button" style={this.cssProperty}
                 onClick={this.startTraining}>
                <div className="span-button">{this.props.text ?? "НАЧАТЬ"}</div>
            </div>
        )
    }
}