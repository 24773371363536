import {makeAutoObservable} from "mobx";

export enum MatryoshkaState {
    remember = 0,
    writeAnswer = 1,
    checkAnswer = 2,
}

export interface MatryoshkaItemModel {
    id: number;
    word: string;
    answerUser?: string;
    isHint?: boolean;
    isCorrect?: boolean;
}

export interface MatryoshkaModel {
    items: MatryoshkaItemModel[];
    countRetries: number;
}

export class MatryoshkaStore {
    state: MatryoshkaState;
    model: MatryoshkaModel = {
        countRetries: 0,
        items: [{
            id: 0,
            word: "бокал",
        }, {
            id: 1,
            word: "иллюминатор",
        }, {
            id: 2,
            word: "тормоз",
        }, {
            id: 3,
            word: "казино",
        }, {
            id: 4,
            word: "забор",
        },]
    }

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.state = MatryoshkaState.remember;
    }

    onChange(val: string, position: number) {
        this.model.items[position].answerUser = val;
        console.log('www', this.model.items[position].answerUser);
    }

    clickHint(position: number) {
        this.model.items[position].isHint = true;
    }

    finish() {
    }

    nextStep() {
        if (this.state === MatryoshkaState.remember) {
            this.state = MatryoshkaState.writeAnswer;
            return;
        }
        if (this.state === MatryoshkaState.writeAnswer) {
            this.state = MatryoshkaState.checkAnswer;
            for (const item of this.model.items) {
                item.isCorrect = item.answerUser === item.word;
            }
            return;
        }
        if (this.state === MatryoshkaState.checkAnswer) {
            if (this.model.items.filter(x => x.word !== x.answerUser).length > 0) {
                this.state = MatryoshkaState.writeAnswer;
                this.model.countRetries++;
                for (const item of this.model.items) {
                    item.answerUser = undefined;
                    item.isHint = undefined;
                    item.isCorrect = undefined;
                }
            } else {
                this.finish();
            }
            return;
        }
    }
}