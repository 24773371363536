import {makeAutoObservable} from "mobx";
import React from "react";
import {Loading} from "../../shared/Loading";
import {
    AdjectiveTrainingItemType,
    TrainingMemoryRussianMemberItemRequestViewModel,
    TrainingMemoryRussianMemberItemViewModel,
    TrainingMemoryRussianMemberViewModel,
    TrainingMemoryRussianStage
} from "../model/LearnLanguageMemberModel";
import {AudioComponent} from "../../../helpers/audio/AudioComponent";
import {CheckTextComponent} from "../../../helpers/checkText/CheckTextComponent";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../helpers/img/ImageComponent";
import {ChooseAnswerTrainingLearnLanguage} from "../components/ChooseAnswerTrainingLearnLanguage";
import {LanguageMemberTransportFunctionModel} from "../model/LanguageMemberTransportFunctionModel";
import {compareWordsCheck} from "../../../helpers/HelperPoints";


export default class TrainingLearnLanguageMemberStore {
    model: TrainingMemoryRussianMemberViewModel
    itemsRequest: TrainingMemoryRussianMemberItemRequestViewModel[] = [];
    countElementsOneTime: number = 1;
    numberElNow: number = 0;
    elementAtGo: number = 1;
    showHint: boolean = false;
    lastDate: Date;
    transportFunction: LanguageMemberTransportFunctionModel

    timer: NodeJS.Timer | undefined = undefined;

    timerAnswer: NodeJS.Timer | undefined = undefined;
    isClickAnswer: boolean = false;


    setModel(model: TrainingMemoryRussianMemberViewModel) {
        this.model = model;
    }

    constructor(transportFunction: LanguageMemberTransportFunctionModel,
                model: TrainingMemoryRussianMemberViewModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.transportFunction = transportFunction;
        this.model = model;
        this.fillingItems();
        this.lastDate = new Date();
    }

    private fillingItems() {
        for (let i = this.itemsRequest.length; i < this.model.items.length; i++) {
            this.itemsRequest.push({
                id: this.model.items[i].id,
                timeElement: 0,
                answer: "",
                tryCount: 0
            })
        }
    }

    init() {
    }

    unmount() {
    }

    setUserAnswer(val: string) {
        this.itemsRequest[this.numberElNow].answer = val;
    }

    next() {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow < 0) return;
        this.clearAfterAnswer();
        this.showHint = false;
        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.itemsRequest.length &&
               (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
            firsStart = false;
            if (!this.itemsRequest[elDate].timeElement)
                this.itemsRequest[elDate].timeElement = 0;
            this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
        }
        this.numberElNow += this.countElementsOneTime;
        this.lastDate = new Date();
        if (this.numberElNow >= this.itemsRequest.length) {
            if (this.model.isAfterFinish) {
                this.resultTrainingClick();
            }
            this.continueTrainingClick();
        }
    }

    back(): void {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow <= 0) {
            return;
        }
        this.clearAfterAnswer();
        this.showHint = false;
        let firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.itemsRequest.length &&
               (elm1 % this.countElementsOneTime !== 0 || firsStart === true); elm1++) {
            firsStart = false;
            if (!this.itemsRequest[elm1].timeElement)
                this.itemsRequest[elm1].timeElement = 0;
            this.itemsRequest[elm1].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countNow;
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        this.lastDate = new Date();
    }

    clearAfterAnswer() {
        this.isClickAnswer = false;
    }

    clickAnswer(answer: string): void {
        this.itemsRequest[this.numberElNow].answer = answer;
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, 1000);
    }

    checkAnswer(answer: string): boolean | undefined {
        if (!this.isClickAnswer)
            return undefined;
        if (this.currentItem().nameRus === answer)
            return true;
        if (this.itemsRequest[this.numberElNow].answer !== answer)
            return undefined;

        return this.currentItem().nameRus === answer;
    }

    checkAnswerWrote() {
        if (!compareWordsCheck(this.itemsRequest[this.numberElNow].answer, this.currentItem().nameEng)) {
            this.itemsRequest[this.numberElNow].tryCount++
            if (this.itemsRequest[this.numberElNow].tryCount >= 2) {
                this.showHint = true;
            }
            return
        }
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, 1000);
    }

    checkAnswerWords(isCorrect: boolean): void {
        if (!isCorrect) {
            this.itemsRequest[this.numberElNow].tryCount++
            if (this.itemsRequest[this.numberElNow].tryCount >= 2) {
                this.showHint = true;
            }
            return
        }
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, 1000);
    }

    clickHint() {
        this.showHint = !this.showHint;
        this.itemsRequest[this.numberElNow].tryCount++;
    }

    isShowLeftAndRight(): boolean {
        return this.currentItem()?.itemType !== AdjectiveTrainingItemType.SelectAnswer;
    }

    isShowCheck(): boolean {
        return this.currentItem()?.itemType === AdjectiveTrainingItemType.WriteEnglishWithEnglishWord ||
            this.currentItem()?.itemType === AdjectiveTrainingItemType.WriteTitleWithRussianWord;
    }

    private currentItem(): TrainingMemoryRussianMemberItemViewModel {
        return this.model.items[this.numberElNow];
    }

    get view(): JSX.Element {
        if (this.itemsRequest.length <= this.numberElNow)
            return <Loading/>
        let model = this.currentItem();

        if (model.itemType === AdjectiveTrainingItemType.Member) {
            return <div className="training-learn-language">
                <TitleComponent text={model.nameRus} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                <TitleComponent text={model.translationRus} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.description}/>
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.SelectAnswer) {
            return <div className="training-learn-language">
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                <div className="container-answers">
                    {
                        model.answers?.map(x => <ChooseAnswerTrainingLearnLanguage key={model.id + "_" + x}
                                                                                   click={this.clickAnswer}
                                                                                   answer={x}
                                                                                   disabled={this.isClickAnswer}
                                                                                   checkAnswer={this.checkAnswer(x)}/>)
                    }
                </div>
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Name) {
            return <div className="training-learn-language">
                {
                    this.showHint &&
                    <TitleComponent key={model.nameRus}
                                    text={model.nameRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.bigTitle}/>
                }
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                {
                    this.showHint &&
                    <TitleComponent key={model.translationRus}
                                    text={model.translationRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                }
                {
                    this.model.stage === TrainingMemoryRussianStage.IncodeCoding &&
                    <CheckTextComponent key={"CheckTextComponent" + model.translationRus}
                                        answer={this.itemsRequest[this.numberElNow].answer}
                                        correctAnswer={model.translationRus}
                                        changeText={this.setUserAnswer}
                                        tryAnswer={this.checkAnswerWords}/>
                }
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Image) {
            return <div className="training-learn-language">
                <TitleComponent key={model.nameRus}
                                text={model.nameRus}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
                {
                    this.showHint &&
                    <>
                        <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                        <TitleComponent key={model.translationRus}
                                        text={model.translationRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.description}/>
                    </>
                }
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Audio) {
            return <div className="training-learn-language">
                <AudioComponent key={"audio_" + model.audio} src={model.audio}/>
                {
                    this.showHint &&
                    <>
                        <TitleComponent key={model.nameRus}
                                        text={model.nameRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.bigTitle}/>
                        <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                        <TitleComponent key={model.translationRus}
                                        text={model.translationRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.description}/>
                    </>
                }
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.WriteTitleWithRussianWord ||
            model.itemType === AdjectiveTrainingItemType.WriteEnglishWithEnglishWord) {
            return <div className="training-learn-language">
                <TitleComponent key={model.nameRus}
                                text={model.nameRus}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
                <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>

                {
                    this.showHint &&
                    <>
                        <TitleComponent key={model.transcriptionAmerica}
                                        text={model.transcriptionAmerica}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.description}/>
                    </>
                }
                <CheckTextComponent key={"CheckTextComponent" + model.translationEng}
                                    answer={this.itemsRequest[this.numberElNow].answer}
                                    correctAnswer={model.nameEng}
                                    changeText={this.setUserAnswer}
                                    tryAnswer={this.checkAnswerWords}/>
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.MemberEnglishAndAudio) {
            return <div className="training-learn-language">
                <TitleComponent key={model.nameRus}
                                text={model.nameRus}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>

                <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>

                <AudioComponent key={"audio_" + model.audio} src={model.audio}/>

                <TitleComponent key={model.nameEng}
                                text={model.nameEng}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.description}/>

                <TitleComponent key={model.transcriptionAmerica}
                                text={model.transcriptionAmerica}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.description}/>
            </div>
        }
        return <></>;
    }

    get positionInfo(): string {
        return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
            " / " + Math.ceil(this.itemsRequest.length / this.countElementsOneTime);
    }

    resultTrainingClick() {
        this.transportFunction.resultTraining({
            id: this.model.id,
            items: this.itemsRequest
        });
    }

    continueTrainingClick() {
        this.transportFunction.continueTraining({
            id: this.model.id,
            items: this.itemsRequest
        }).then(x => {
            let model = x.Model;
            if (x.Model !== null) {
                this.setModel(model!);
                this.fillingItems();
            }
        });
    }

    finishTrainingClick() {
        this.transportFunction.finishTraining({
            id: this.model.id,
        });
    }
}