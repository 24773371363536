import {CustomComponent} from "../CustomComponent";
import './imageComponent.scss'
import {observer} from "mobx-react";


interface Props{
    src: string;
    alt: string
}
@observer
export class ImageComponent extends CustomComponent<Props, any>{

    render() {
        return <>
            <img className="image-component" src={"https://powermemory.io" + this.props.src} alt={this.props.alt}/>
        </>;
    }
}