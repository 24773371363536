import {CustomComponent} from "../../../helpers/CustomComponent";
import './matryoshkaItemComponent.scss'
import {MatryoshkaItemModel} from "./MatryoshkaStore";
import {observer} from "mobx-react";
import {ChangeEvent} from "react";

interface Props {
    model: MatryoshkaItemModel;
    position: number
    onChange: (val: string, position: number) => void;
    clickHint?: (position: number) => void;
}

@observer
export class MatryoshkaItemComponent extends CustomComponent<Props, any> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.onchange = this.onchange.bind(this);
        this.clickHint = this.clickHint.bind(this);
    }

    onchange(e: ChangeEvent<HTMLInputElement>) {
        this.props.onChange(e.target.value, this.props.position)
    }

    clickHint() {
        if (this.props.clickHint)
            this.props.clickHint(this.props.position);
    }

    render() {
        return <div className="matryoshka_item_component">
            <div className="position-cell">{this.props.position + 1}</div>
            {
                this.props.model.isHint !== true &&
                <div onClick={this.clickHint}
                     className="view-hint" title="Показать правильный ответ"></div>
            }
            {
                this.props.model.isHint === true &&
                <input readOnly={true}
                       className="ans-input ans-input-show-hint"
                       value={this.props.model.word}/>
            }
            {
                this.props.model.isHint !== true &&
                <input className="ans-input"
                       onChange={this.onchange}
                       value={this.props.model.answerUser}/>
            }
        </div>;
    }
}