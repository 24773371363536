import {MRT_ColumnDef} from "mantine-react-table";
import {UserAdminViewModel} from "../../components/administration/model/ProductView";
import {type} from "node:os";
import React from "react";
import {AvatarWithMnemocard} from "../../components/user/AvatarWithMnemocard";


export function CustomHeaderFromJson(body: object[]) {

}

export function CustomHeader<T extends Record<string, any>>(body: T[], type: string):
    MRT_ColumnDef<UserAdminViewModel | object>[] {
    if (type === "UserAdminViewModel")
        return headerUserAdminViewModel;
    return [];
}


const maxValue = (items?: number[]) => {
    return Math.max(...items?.filter(x => x) ?? [0]) ?? 0;
}
const minValue = (items?: number[]) => {
    return Math.min(...items?.filter(x => x) ?? [0]) ?? 0;
}


const headerUserAdminViewModel: MRT_ColumnDef<UserAdminViewModel>[] = [
    {
        accessorKey: 'id',
        header: 'Ник канала',
        Cell: ({cell}) => <div>{cell.getValue()}</div>
        // <AvatarWithMnemocard
        //     name={cell.getValue()}
        //     image={cell.img}
        //     isPremium={cell.isPremium}
        //     link={cell.link}
        //     rank={cell.rank}/>
        // Cell: ({cell}) => <a href={"https://t.me/" + cell.getValue()} target="_blank"
        //                      rel="noopener noreferrer">{cell.getValue()}</a>,
    },
    {
        accessorKey: 'fullname',
        header: 'ФИО',
    },
    {
        accessorKey: 'rank',
        header: 'Ранг',
    },
    {
        accessorKey: 'balls',
        header: 'Кол-во баллов',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        accessorFn: (row) => row.balls ?? '',
        // mantineFilterRangeSliderProps: {
        //     step: 1,
        //     min: minValue(columns?.map(o => parseInt(o?.AvgOnePublish))),
        //     max: maxValue(columns?.map(o => parseInt(o.AvgOnePublish))),
        // },
    },
    {
        accessorKey: 'dateRegistration',
        header: 'Дата регистрации',
    },
    {
        accessorKey: 'dateLastLoginTime',
        header: 'Дата последней авторизации',
    },
    {
        accessorKey: 'dateTraining',
        header: 'Дата последней тренировки',
    },
];