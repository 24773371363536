import {makeAutoObservable} from "mobx";
import {UniversityBlockTaskModel} from "../models/UniversityBlockTaskModel";
import {UniversityStateTask} from "../models/UniversityStateTask";
import {UniversityTypeTask} from "../models/UniversityTypeTask";


export class UniversityModuleStore {

    private models: UniversityBlockTaskModel[] = [
        {
            id: 1,
            name: "Тестовый блок",
            tasks: [{
                id: 1,
                name: "ТЕСТ НА ЗНАНИЕ ЗАНЯТИЯ №1",
                state: UniversityStateTask.wait,
                link: "/",
                type: UniversityTypeTask.task,
                image: "https://powermemory.io/images/users/f3590673-90df-4c3b-bfe8-40dbc37dea58.jpg",
            },{
                id: 2,
                name: "КЛЮЧЕВЫЕ СЛОВАК ЗАНЯТИЮ №1",
                state: UniversityStateTask.success,
                link: "string",
                type: UniversityTypeTask.task,
                image: "https://powermemory.io/images/users/f3590673-90df-4c3b-bfe8-40dbc37dea58.jpg",
            },{
                id: 3,
                name: "ЧТЕНИЕ КОНСПЕКТА К ЗАНЯТИЮ №1",
                state: UniversityStateTask.wait,
                link: "string",
                type: UniversityTypeTask.task,
                image: "https://powermemory.io/images/users/f3590673-90df-4c3b-bfe8-40dbc37dea58.jpg",
            }],
        }
    ];

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    init() {

    }

    unInit() {

    }

    get universityBlocks(): UniversityBlockTaskModel[] | undefined {
        return this.models;
    }
}