import {makeAutoObservable} from "mobx";
import {compareWordsCheck} from "../HelperPoints";


export class CheckTextStore {
    private readonly changeText: (val: string) => void;
    private readonly correctAnswer: string = "";
    answer: string = "";
    private currentStatus: CheckTextStatus = CheckTextStatus.noCheck;
    private readonly tryAnswer: (isCorrect: boolean) => void;

    constructor(correctAnswer: string,
                answer: string,
                changeText: (val: string) => void,
                tryAnswer: (isCorrect: boolean) => void) {
        this.correctAnswer = correctAnswer;
        this.answer = answer;
        this.tryAnswer = tryAnswer;
        this.changeText = changeText;
        makeAutoObservable(this, {}, {autoBind: true})
    }

    changeAnswer(answer: string) {
        this.answer = answer;
        this.changeText(answer);
        this.emptyStatus();
    }

    get status(): CheckTextStatus {
        return this.currentStatus;
    }

    emptyStatus() {
        this.currentStatus = CheckTextStatus.noCheck;
    }

    checkText() {
        this.currentStatus = compareWordsCheck(this.correctAnswer, this.answer)
            ? CheckTextStatus.valid
            : CheckTextStatus.noValid;
        this.tryAnswer(this.currentStatus === CheckTextStatus.valid);
    }
}

export enum CheckTextStatus {
    noCheck = 0,
    valid = 1,
    noValid = 2,
}