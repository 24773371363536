import {CustomComponent} from "../CustomComponent";
import {observer} from "mobx-react";
import {MRT_Localization_RU} from "mantine-react-table/locales/ru";
import {MantineReactTable, MRT_ColumnDef} from "mantine-react-table";
import React from "react";
import {CustomHeader} from "./GetColumnsHelper";


interface Props<T> {
    data: T[]
}

@observer
export class MantineReactCustomTable<T extends Record<string, any>> extends CustomComponent<Props<T>, any> {
    constructor(props: Readonly<Props<T>>) {
        super(props);
        this.getHeader = this.getHeader.bind(this);
    }
    // Доделать пагинацию с сортировкой и фильтрацией

    getHeader(): MRT_ColumnDef<T>[] {
        if (this.props.data.length >= 1){
            return CustomHeader<T>(this.props.data, "UserAdminViewModel");
        }
        return [];
    }

    render() {
        return <MantineReactTable
            initialState={{density: 'xs'}}
            columns={this.getHeader()}
            data={this.props.data}
            enableSorting={true}
            enableStickyHeader={true}
            enableTableHead={true}
            enableRowSelection={true}
            enableColumnResizing={true}
            enableColumnActions={false}
            enableRowActions={false}
            enableDensityToggle={true}
            enableFullScreenToggle={false}
            localization={MRT_Localization_RU}
            mantinePaginationProps={{
                rowsPerPageOptions: ['10', '50', '100', '500'],
            }}
        />;
    }
}