import React from 'react'
import './trainingLearnLanguageMemberWriteComponent.scss'
import {observer} from "mobx-react";
import {CustomButton} from "../../helpers/CustomButton";
import Modal from "../modal/ReactModal";
import {ReactModalStore} from "../modal/ReactModalStore";
import {CustomComponent} from "../../helpers/CustomComponent";
import {CustomButtonView} from "../training/components/CustomButtonView";
import {TrainingMemoryRussianMemberViewModel, TrainingMemoryRussianStage} from "./model/LearnLanguageMemberModel";
import {TitleComponent, TypePositionText, TypeTitle} from "../../helpers/title/TitleComponent";
import {SpaceComponent, TypeSpaceComponent} from "../../helpers/space/SpaceComponent";
import {BottomBlockComponent} from "../../helpers/bottomBlock/BottomBlockComponent";
import KeyboardManager from "../../core/keyboardManager/adapters/KeyboardManager";


interface Props {
    finishTrainingRequest: () => void,
    model: TrainingMemoryRussianMemberViewModel
}

@observer
export class TrainingLearnLanguageDescriptionStageComponent extends CustomComponent<Props, {}> {
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();
    models: LearnLanguageViewModel[] = [{
        step: 1,
        stage: TrainingMemoryRussianStage.FormationLibrary,
        title: "ЭТАП 1: ЗАПОМИНАНИЕ СЛОВООБОРАЗОВ",
        description: "Установите связь между каждым русским словом и соответствующим словообразом.",
        about: "Например, русское слово «прыгать» связано со словообразом «скакалка».\n" +
            "Установить связь в этом примере означает понять, почему образ «скакалки»\n" +
            "можно ассоциировать со словом «прыгать».",
    }, {
        step: 2,
        title: "ЭТАП 2: ДЕКОДИРОВАНИЕ СЛОВООБРАЗОВ",
        stage: TrainingMemoryRussianStage.FormationLibrary,
        description: "Посмотрите на словообраз и озвучьте громко про себя или вслух слово, которое ему соответствует.",
        about: "Если не уверены в ответе или не можете вспомнить слово," +
            "нажмите на кнопку подсказки в верхнем правом углу экрана или на кнопку со стрелкой вверх " +
            "для получения подсказки и усиления связи.",
    }, {
        step: 3,
        title: "ЭТАП 3: КОДИРОВАНИЕ СЛОВ В СЛОВООБРАЗЫ",
        stage: TrainingMemoryRussianStage.FormationLibrary,
        description: "От каждого показанного слова представьте соответствующий словообраз на мысленном экране.",
        about: "Если не уверены в ответе или не можете вспомнить словообраз, нажмите на кнопку подсказки " +
            "в верхнем правом углу экрана или на кнопку со стрелкой вверх для получения подсказки и усиления связи.",
    }, {
        step: 4,
        title: "ЭТАП 4: КОДИРОВАНИЕ АУДИО В СЛОВООБРАЗЫ",
        stage: TrainingMemoryRussianStage.FormationLibrary,
        description: "От каждого прослушанного слова представьте соответствующий словообраз на мысленном экране.",
        about: "Если не уверены в ответе или не можете вспомнить словообраз, нажмите на кнопку подсказки " +
            "в верхнем правом углу экрана или на кнопку со стрелкой вверх для получения подсказки и усиления связи.",
    }, {
        step: 1,
        title: "ЭТАП 5: ЗАПОМИНАНИЕ ИНКОДОВ",
        stage: TrainingMemoryRussianStage.IncodeCoding,
        description: "Установите связь между каждым инкодом и соответствующим сегментом.",
        about: "Например, инкод «образ Малины» связан с сегментом «Mal» через кодирование достройкой.\n" +
            "Установить связь в этом примере означает понять, почему инкод «образ Малина»\n" +
            "можно связать с комбинацией латинских букв - сегментом «Mal», а также понять приём кодирования, который был использован.",
    }, {
        step: 2,
        title: "ЭТАП 6: ДЕКОДИРОВАНИЕ ИНКОДОВ В СЕГМЕНТЫ",
        stage: TrainingMemoryRussianStage.IncodeCoding,
        description: "Посмотрите на инкод и впишите соответствующий ему сегмент по памяти.",
        about: "Если не уверены в ответе или не можете вспомнить сегмент, нажмите на кнопку подсказки в верхнем " +
            "правом углу экрана или на кнопку со стрелкой вверх для получения подсказки и усиления связи.",
    }, {
        step: 3,
        title: "ЭТАП 7: КОДИРОВАНИЕ СЕГМЕНТОВ В ИНКОДЫ",
        stage: TrainingMemoryRussianStage.IncodeCoding,
        description: "Установите связь между каждым инкодом и соответствующим сегментом.",
        about: "Если не уверены в ответе или не можете вспомнить инкод, " +
            "нажмите на кнопку подсказки в верхнем правом углу экрана или на кнопку со стрелкой вверх для получения подсказки и усиления связи.",
    }, {
        step: 8,
        title: "ЭТАП 8: ПЕРВИЧНОЕ ЗАПОМИНАНИЕ НАПИСАНИЯ АНГЛИЙСКИХ СЛОВ",
        stage: TrainingMemoryRussianStage.stage3,
        description: "Запомините написание английских слов через установку связи между частями " +
            "словообраза и инкодами посредством мысленной проекцией.",
        about: "Например, английское слово «able (способный)» связано со словообразом «Пианист (способный человек)». " +
            "Для запоминания английского написания, нужно представить как мысленный образ «АБЛЕзлый кот» " +
            "врезается в реальную картинку пианиста на экране.\n\n" +
            "«АБЛЕзлый кот» является инкодом — визуальным образом для сегмента «able»." +
            "Мысленная проекция — это установка связи между мысленным образом и реальной картинкой. ",
    }, {
        step: 9,
        title: "ЭТАП 9: УСИЛЕНИЕ СВЯЗЕЙ НА НАПИСАНИЕ АНГЛИЙСКИХ СЛОВ (LIGHT)",
        stage: TrainingMemoryRussianStage.stage3,
        description: "Вспомните написание английских слов от каждого русского слова опираясь " +
            "на реакции инкодов от словообраза.",
        about: "Например, русское слово «Уверенный» связано со словообразом «Боксёр (уверенный в своих силах)». " +
            "Для припоминания английского написания, нужно рассмотреть словообраз и увидить на его частях инкоды на " +
            "сегменты «con», «fi», «dent» после чего ввести ответ в полее ввода.",
    },];

    constructor(props: Readonly<Props>) {
        super(props);
        this.closeTraining = this.closeTraining.bind(this);
    }

    componentDidMount() {
        KeyboardManager.add('Space', this.props.finishTrainingRequest);

    }
    componentWillUnmount() {
        KeyboardManager.remove('Space', this.props.finishTrainingRequest);
    }

    closeTraining() {
        window.location.reload();
    }

    get model(): LearnLanguageViewModel | undefined {
        console.log('ee', this.props.model);
        return this.models.filter(x => x.step === this.props.model.step
            && x.stage === this.props.model.stage)[0];
    }

    render() {
        return (
            <div className="training-learn-language">
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Закрыть">
                        <p><span className="ui-icon ui-icon-alert"
                                 style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                        <div className="dialog-form-block-buttons">
                            <CustomButton text="Нет" onclick={this.reactModalStore.close}/>
                            <CustomButton text="Закрыть тренировку" onclick={this.closeTraining}/>
                        </div>
                    </div>
                </Modal>

                <div className="learn-language-container">

                    <TitleComponent text={this.model?.title} typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.bigTitle}/>

                    <SpaceComponent type={TypeSpaceComponent.lvl1}/>
                    <TitleComponent text={this.model?.description} typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>

                    <SpaceComponent type={TypeSpaceComponent.lvl2}/>

                    <TitleComponent text={this.model?.about} typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.about}/>

                    <SpaceComponent type={TypeSpaceComponent.lvl1}/>
                    <BottomBlockComponent>
                        <CustomButtonView click={this.props.finishTrainingRequest}/>
                    </BottomBlockComponent>
                </div>
            </div>
        )
    }
}

interface LearnLanguageViewModel {
    step: number;
    stage: TrainingMemoryRussianStage;
    title: string;
    description: string;
    about: string;
}