import React, {ChangeEvent} from 'react'
import './newsPostPage.scss'
import {observer} from "mobx-react";
import {PostStore} from "../../components/post/store/PostStore";
import {Loading} from "../../components/shared/Loading";
import Comment from "../../components/post/Comment";
import AuthorizationStore from "../../components/authorization/store/AuthorizationStore";
import {Avatar} from "../../components/user/Avatar";
import {CustomComponent} from "../../helpers/CustomComponent";
import {Link, useParams} from "react-router-dom";
import {getDateRes} from "../../helpers/numberHelp/numberHelp";
import TitleStore from "../../components/authorization/store/TitleStore";
import Footer from "../../components/shared/Footer";
import {ButtonScrollToTop} from "../../helpers/scrollTop/ButtonScrollToTop";

@observer
class NewsPage extends CustomComponent<{ link: string }, {}> {
    store: PostStore;

    constructor(props: Readonly<{ link: string }>) {
        super(props);
        this.store = new PostStore(this.props.link)
        this.clickLike = this.clickLike.bind(this);
        this.clickDelete = this.clickDelete.bind(this);
        this.createComment = this.createComment.bind(this);
        this.createComment = this.createComment.bind(this);
        this.changeTextComment = this.changeTextComment.bind(this);
    }

    componentDidMount() {
        TitleStore.setTitle("Новости: ");
        this.store.fetchRequest();
    }

    clickLike(commentId: string) {
        this.store.clickLike(commentId);
    }

    clickDelete(commentId: string) {
        this.store.clickDelete(commentId);
    }

    createComment() {
        this.store.createComment();
    }

    changeTextComment(e: ChangeEvent<HTMLTextAreaElement>) {
        this.store.changeTextComment(e.target.value ?? "")
    }

    render() {
        return (
            <div className="news-post-page">
                {!this.store.model.IsLoaded &&
                    <Loading/>}
                {this.store.model.IsLoaded && this.store.model.Model &&
                    <>
                        <div id="news-post">
                            <hr className="news-page"/>
                            <div className="news-theme">{this.store.model.Model.newsTheme}</div>

                            <div className="news-stat">
                                <a href="#comments">
                                    <div
                                        className="page-comm">{this.store.model.Model.commsUser?.comms?.length ?? 0}</div>
                                </a>
                                <div className="page-view">{this.store.model.Model.view}</div>
                                <div
                                    className="author-date author-date2">{this.store.model.Model.author} | {getDateRes(this.store.model.Model.dateTime)}</div>
                            </div>

                            <h1 id="page-name">{this.store.model.Model.pageName}</h1>
                            <div className="news-theme">{this.store.model.Model.newsTheme}</div>
                            <div dangerouslySetInnerHTML={{__html: this.store.model.Model.html!}}>
                            </div>
                            <div className="line-solid"></div>
                            <div id="teg-block">
                                {this.store.model.Model.tags?.map((x) =>
                                    <a key={x} className="news-teg" href={"/Post?tag=" + x}>#{x}</a>
                                )}
                            </div>
                        </div>

                        <div id="repost-page">
                            <Link to={"https://telegram.me/share/url?url=" + this.store.getLink} target="_blank"
                               rel="nofollow noopener"
                               className="social__button">
                                <div className="rep-icons" id="tg-rep"></div>
                            </Link>
                            <Link to={"https://api.whatsapp.com/send?text=" + this.store.getLink} target="_blank"
                               rel="nofollow noopener"
                               className="social__button">
                                <div className="rep-icons" id="wh-rep"></div>
                            </Link>
                            <Link to={"https://vk.com/share.php?url=" + this.store.getLink} target="_blank"
                               rel="nofollow noopener"
                               className="social__button">
                                <div className="rep-icons" id="vk-rep"></div>
                            </Link>
                            <Link to ={"https://twitter.com/share?url=" + this.store.getLink} target="_blank"
                               rel="nofollow noopener"
                               className="social__button">
                                <div className="rep-icons" id="tw-rep"></div>
                            </Link>
                            <Link to={"https://connect.ok.ru/offer?url=" + this.store.getLink} target="_blank"
                               rel="nofollow noopener"
                               className="social__button">
                                <div className="rep-icons" id="ok-rep"></div>
                            </Link>
                        </div>
                        <div id="comm-block-sub">
                            <div id="comm-block" ref={(e) => this.store.commentsRef = e}>
                                <div className="comments">
                                    <span className="w-span">
                                        Комментарии ({this.store.model.Model.commsUser?.comms?.length ?? 0})
                                    </span>
                                </div>
                                <div id="comments">
                                    {this.store.comms?.map((x) =>
                                        <Comment key={x.id}
                                                 isAdminOrSameUser={AuthorizationStore.checkAuthMenu &&
                                                     (x.idAccount === AuthorizationStore.user.Model!.userId || AuthorizationStore.user.Model!.isAdmin)}
                                                 comm={x}
                                                 liked={AuthorizationStore.user?.Model?.userId === undefined
                                                     ? false
                                                     : x.likesOfNameUser.includes(AuthorizationStore.user?.Model?.userId ?? undefined)}
                                                 clickDelete={this.clickDelete}
                                                 clickLike={this.clickLike}
                                        />
                                    )}
                                </div>

                                {AuthorizationStore.checkAuthMenu &&
                                    <div className="comm-users">
                                        <Avatar fullname={AuthorizationStore.user.Model?.fullname ?? ""}
                                                img={AuthorizationStore.user.Model?.imgSrc ?? ""}
                                                style={{top: "34px"}}
                                                link={AuthorizationStore.user.Model?.link ?? ""}
                                        />
                                        <div>
                                            <div className="w-span" style={{marginBottom: '15px'}}>Ваш комментарий</div>
                                            <textarea id="comm-write"
                                                      value={this.store.commentText}
                                                      onChange={this.changeTextComment}
                                                      placeholder={"Ваше мнение, " + AuthorizationStore.user.Model?.fullname}
                                                      name="text">{this.store.commentText}</textarea>
                                            <input type="button" value="Отправить" className="comm-enter"
                                                   onClick={this.createComment}
                                            />
                                        </div>
                                    </div>
                                }
                                {!AuthorizationStore.checkAuthMenu &&
                                    <div id="auth-comm">
                                        <p><span>Авторизируйтесь</span>, чтобы оставить комментарий</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <ButtonScrollToTop />
                    </>
                }
                <Footer />
            </div>
        )
    }
}

function NewsPostPage() {
    const param = useParams();
    return (
        <NewsPage link={param['link']!}/>
    );
}

export default NewsPostPage;