import React, {Component} from 'react'
import './../training/trainingBaseComponent.scss'
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {CustomButtonView} from "../training/components/CustomButtonView";
import {MainCodingComponent} from "./components/MainCodingComponent";
import {RouteMemberCoding} from "./components/RouteMemberCoding";
import {RangeTimeCodingComponent} from "./components/RangeTimeCodingComponent";
import TrainingSportBaseStore from "./store/TrainingSportBaseStore";
import {Loading} from "../shared/Loading";
import {CodingViewModel} from "./model/PackageTrainingCodingViewModel";

interface Props{

}
@observer
export class TrainingCodingComponent extends Component<{ startTraining: (model: CodingViewModel) => void }, {}> {
    store: TrainingSportBaseStore;

    constructor(props: Readonly<{ startTraining: (model: CodingViewModel) => void }>) {
        super(props);
        this.store = new TrainingSportBaseStore(this.props.startTraining);
    }

    componentDidMount() {
        this.store.init();
    }

    componentWillUnmount() {
        this.store.unInit();
    }

    render() {
        if (!this.store.responseModel.IsLoadedModel())
            return <Loading/>
        return (
            <div className="training-sport-base">
                <div className="mode-training">
                    <Link to={"/Training"} id="next-simulators-label"/>
                </div>

{/* ИСПРАВЬ Добавь класс и перенеси в стили*/ }
                <div style={{
                            maxWidth: '1110px',
                            width: '100%',
                            display: 'flex',
                            backgroundColor: '#fff',
                            justifyContent: 'center'
                            }}>
                    <div className="simulators">
                        <RouteMemberCoding store={this.store}/>

                        <div className="modeTypeTraining">
                            <MainCodingComponent store={this.store}/>
                            <CustomButtonView click={this.store.startTraining}/>
                        </div>
                        <RangeTimeCodingComponent store={this.store}/>
                    </div>
                </div>

            </div>
        )
    }
}