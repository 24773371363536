import CustomSelector, {SelectorGroupPros, SelectorPros} from "./CustomSelector";
import {CustomComponent} from "../../../helpers/CustomComponent";
import './selectorWithText.scss'


interface Props {
    defaultValue?: string;
    value?: string;
    values?: SelectorPros[];
    groups?: SelectorGroupPros[];

    onChange?(sel: SelectorPros | undefined): void;

    width?: string
    margin?: string
    text: string
}

export default class SelectorWithText extends CustomComponent<Props, {}> {
    render() {
        return <div className="selector-with-text" style={{width:this.props.width ?? '225px'}}>
            <div className="with-text-title">{this.props.text}</div>
            <CustomSelector
                defaultValue={this.props.defaultValue}
                values={this.props.values}
                value={this.props.value}
                onChange={this.props.onChange}
                width={this.props.width}
                margin={this.props.margin}
            />
        </div>;
    }
}