import {CustomComponent} from "../../helpers/CustomComponent";
import './universityModule.scss'
import {TaskComponent} from "../../helpers/task/TaskComponent";
import {observer} from "mobx-react";
import {TitleComponent, TypePositionText, TypeTitle} from "../../helpers/title/TitleComponent";
import React from "react";
import {BlockTextComponent} from "../../helpers/blockText/BlockTextComponent";
import {UniversityModuleStore} from "./store/UniversityModuleStore";

interface Props {

}

@observer
export class UniversityModuleComponent extends CustomComponent<Props, any> {
    private store: UniversityModuleStore = new UniversityModuleStore();

    componentDidMount() {
        this.store.init()
    }

    componentWillUnmount() {
        this.store.unInit()
    }

    render() {
        return <div className="university-module">
            {
                this.store.universityBlocks &&
                this.store.universityBlocks.map(block =>
                    <div key={"block_id_" + block.id}>
                        <TitleComponent typeTitle={TypeTitle.universityModuleTitle} text={block.name}
                                        typePosition={TypePositionText.left}/>
                        <BlockTextComponent hasBackground={true}>
                            <div className="university-tasks">
                                {block.tasks.map(task =>
                                    <TaskComponent task={task} />)}
                            </div>
                        </BlockTextComponent>
                    </div>)
            }
        </div>;
    }
}
